<template>
  <div class="text-left">
    <BaseHeader :title="'Writers'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-btn
            color="primary"
            class="my-auto ml-2"
            size="small"
            @click.stop="$store.state.dialog = true"
          >New</v-btn> -->
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="writers"
          :search="$store.state.search"
          show-select
          v-model="bulkActionForm.writer_ids"
        >
          <template v-slot:[`item.editor`]="{ item }">
            {{ item.editor || "--" }}
          </template>

          <template v-slot:[`item.level`]="{ item }">
            {{ item.level || "--" }}
          </template>

          <template v-slot:[`item.id`]="{ item }">
            <router-link
              v-if="item.id"
              :to="{
                name: 'WriterProfile',
                params: { id: item.id || '' },
              }"
              >{{ item.id || "--" }}</router-link
            >
          </template>

          <template v-slot:[`item.names`]="{ item }">
            <router-link
              v-if="item.id"
              :to="{
                name: 'WriterProfile',
                params: { id: item.id || '' },
              }"
              >{{ item.names || "--" }}</router-link
            >
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status == '1'"
              outlined
              color="primary"
              text-color="primary"
              style="cursor: pointer"
              small
              @click="
                activeWriter = item;
                dialog = true;
                action = 'deactivate';
              "
              >Active
              <v-icon color="primary" text-color="primary" right
                >mdi-close</v-icon
              ></v-chip
            >
            <v-chip
              style="cursor: pointer"
              v-else
              color="error"
              text-color="white"
              small
              @click="
                activeWriter = item;
                dialog = true;
                action = 'activate';
              "
              >Inactive <v-icon right>mdi-pencil</v-icon></v-chip
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              fab
              elevation="0"
              class="white--text"
              x-small
              @click="editWriterPreferences(item)"
              icon
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="card-footer" v-if="bulkActionForm.writer_ids.length > 0">
          <p>With selected:</p>
          <div class="btn-group">
            <button
              :disabled="bulkActionForm.busy"
              class="btn bg-teal"
              @click="bulkAction('MakeEditor')"
            >
              Make Editor
            </button>
            <button
              :disabled="bulkActionForm.busy"
              class="btn bg-primary"
              @click="bulkAction('MakeWriter')"
            >
              Make Writer
            </button>
          </div>
        </div>
        <!-- /datatable -->
      </div>

      <!-- activate deactivate writer -->
      <v-dialog
        v-model="dialog"
        max-width="290"
        class="text-left"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 text-capitalize">
            {{ action }} writer
          </v-card-title>

          <v-card-text class="text-left">
            Are you sure you want to {{ action }}
            <span class="text-weight-bold">{{ activeWriter.names || "" }}</span>
          </v-card-text>

          <v-card-actions>
            <button color="error darken-1" text @click="dialog = false">
              Cancel
            </button>

            <v-btn
              color="green darken-1"
              text
              @click="changeStatus(action)"
              :disabled="writerPrefsForm.busy"
            >
              Yes, {{ action }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- activate deactivate writer -->

      <!-- writer levels modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Update Writer Preferences
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <v-form class="flex-fill" @submit.prevent="updateWriterPreferences">
              <div class>
                <div class="text-left mb-3">
                  <p class="font-weight-bold">
                    Writer: {{ activeWriter.names || "" }}
                  </p>
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      dense
                      outlined
                      label="Writer Level"
                      name="level_id"
                      :items="writerLevels"
                      item-text="level"
                      item-value="id"
                      v-model="writerPrefsForm.level_id"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerPrefsForm"
                            class="v-messages theme--light error--text"
                            field="level_id"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12" v-if="userType == userTypes.admin">
                    <v-select
                      dense
                      outlined
                      label="Editor"
                      name="editor_id"
                      :items="editors"
                      item-text="names"
                      item-value="id"
                      v-model="writerPrefsForm.editor_id"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerPrefsForm"
                            class="v-messages theme--light error--text"
                            field="editor_id"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col class="text-left">
                    <v-btn
                      :disabled="writerPrefsForm.busy"
                      type="submit"
                      color="teal"
                      class="white--text"
                      >Update</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- writer levels modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Writers",
  computed: {
    ...mapState("writers", [
      "loading",
      "writers",
      "headers",
      "writerPrefsForm",
      "bulkActionForm",
    ]),
    ...mapState("auth", ["user"]),
    ...mapState("editors", ["editors"]),
    ...mapState("writerlevels", ["writerLevels"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },
  data() {
    return {
      countries: [],
      activeWriter: {},
      dialog: false,
      action: "",
    };
  },

  methods: {
    ...mapActions("writers", ["getAllWriters", "updateWriterPrefs"]),
    ...mapActions("editors", ["getEditors"]),
    ...mapActions("writerlevels", ["getWriterLevels"]),

    editWriterPreferences(writer) {
      this.$store.state.dialog = true;
      this.activeWriter = writer;
      this.writerPrefsForm.writer_id = writer.id;
    },

    updateWriterPreferences() {
      if (this.userType == this.userTypes.editor) {
        this.writerPrefsForm.editor_id = this.user.id;
      }
      this.updateWriterPrefs(this.writerPrefsForm)
        .then(() => {
          this.$store.state.dialog = false;
          this.$notify({
            title: "Success",
            text: "Writer preferences updated successifully.",
            style: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: "Error while updating writer preferences",
            style: "danger",
          });
        });
    },

    changeStatus(action) {
      console.log("action", action);
      (this.writerPrefsForm.writer_id = this.activeWriter.id),
        (this.writerPrefsForm.status = action == "activate" ? 1 : Boolean(0));

      this.writerPrefsForm
        .put(`${process.env.VUE_APP_API_BASE_URL}/Writers/Status`)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Writer status updated successifully.",
            style: "success",
          });
          this.dialog = false;
          this.getAllWriters();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: "Error while updating writer status",
            style: "danger",
          });
        });
    },

    bulkAction(action) {
      let writer_ids = this.bulkActionForm.writer_ids.map(
        (item) => item.id || ""
      );

      this.bulkActionForm.writer_ids = [];
      this.bulkActionForm.writer_ids = writer_ids;

      this.bulkActionForm
        .put(`${process.env.VUE_APP_API_BASE_URL}/Writers/${action}`)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Writer status updated successifully.",
            style: "success",
          });
          this.dialog = false;
          this.bulkActionForm.reset();
          this.getAllWriters();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: "Error while updating writer status",
            style: "danger",
          });
        });
    },
  },
  async mounted() {
    this.$store.state.loading = true;
    await this.getAllWriters();
    await this.getWriterLevels();
    if (this.userType == this.userTypes.admin) {
      await this.getEditors();
    }
  },
};
</script>